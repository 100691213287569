module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-5FZ1HNQ4NB"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bobtail Method","short_name":"Bobtail Method","description":"Website for the songwriting lesson plan Bobtail Method.","start_url":"/","background_color":"#e6e6ad","theme_color":"#b38e6b","display":"standalone","icon":"src/assets/favicon/bobtailMethod.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8d9bdc02f6a388d5a46c360d5a03fcd2"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
